html {
    min-height: 100vh;
    max-height: 100vh;
    --main-background-dark: #1a2138;
    --main-background: #222b45;
    --text-primary-1: rgba(255, 255, 255, 0.87);
    --text-primary-2: rgba(255, 255, 255, 0.6);
    --text-primary-3: rgba(255, 255, 255, 0.37);
    --card-background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    height: 100vh;
    max-height: 100vh;
}
